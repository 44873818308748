import request from "@/utils/request";

export function acceptEmployee(data) {
  return request({
    method: "post",
    url: "applicant/accept_employee",
    data: data
  });
}

export function rejectEmployee(data) {
  return request({
    method: "post",
    url: "applicant/reject_employee",
    data: data
  });
}

export function unRejectApplicant(data) {
  return request({
    method: "post",
    url: "applicant/unreject",
    data: data
  });
}

export function getApplicant(data) {
  return request({
    method: "post",
    url: "applicant/porspective_applicants",
    data: data
  });
}

export function acceptApplicant(data) {
  return request({
    method: "post",
    url: "applicant/accept_student",
    data: data
  });
}

export function rejectApplicant(data) {
  return request({
    method: "post",
    url: "applicant/reject_student",
    data: data
  });
}

export function getApplicantByID(data) {
  return request({
    method: "post",
    url: "applicant/get_byid",
    data: data
  });
}
