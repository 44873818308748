<template>
  <div>
    <div>
      <div class="d-flex justify-space-between align-center flex-wrap mt-3">
        <v-radio-group
          row
          v-model="queryData.status"
          hide-details
          class="mt-0"
          @change="
            queryData.page = 1;
            getCandidate();
          "
        >
          <v-radio
            :label="$t('master_data.student.unprocessed')"
            value="UNPROCESSED"
          />
          <v-radio
            :label="$t('master_data.student.not_accepted')"
            value="REJECTED"
          />
        </v-radio-group>
        <v-text-field
          v-model="queryData.search"
          :class="$vuetify.breakpoint.smAndDown && 'mt-3'"
          prepend-inner-icon="mdi-magnify"
          style="max-width: 250px"
          outlined
          dense
          hide-details
          @keydown="getCandidate()"
        />
      </div>

      <v-divider class="my-3" />

      <div class="mb-3 d-flex justify-space-between">
        <div>
          <v-btn
            :loading="loadingDownloadExcel"
            :disabled="dataCandidate.length === 0"
            class="gradient-primary ml-2 caption"
            dark
            depressed
          >
            <download-excel
              :fetch="downloadExcel"
              :fields="fields"
              :name="
                namaMenu +
                  ' ' +
                  (queryData.status == 'UNPROCESSED'
                    ? $t('app.not_received_yet')
                    : $t('app.not_accepted')) +
                  '.xls'
              "
              class="d-inline"
            >
              <span v-if="$vuetify.breakpoint.smAndUp" class="mr-2">
                {{ $t("app.download") }}
              </span>
              <v-icon>mdi-cloud-download</v-icon>
            </download-excel>
          </v-btn>

          <div class="subtitle-2 font-weight-bold d-inline ml-2">
            {{ total }} {{ namaMenu }}
          </div>
        </div>

        <v-spacer />

        <div
          v-if="multipleId.length > 0 && queryData.status == 'UNPROCESSED'"
          class="d-inline"
        >
          <v-btn
            icon
            class="primary white--text mr-3"
            @click="toggleModalConfirm(false, 'ACCEPT')"
          >
            <v-icon>mdi-checkbox-marked-outline</v-icon>
          </v-btn>
          <v-btn
            icon
            class="error white--text"
            @click="toggleModalConfirm(false, 'REJECT')"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
        <div
          v-if="multipleId.length > 0 && queryData.status == 'REJECTED'"
          class="d-inline"
        >
          <v-btn
            text
            icon
            color="purple"
            @click="toggleModalConfirm(false, 'UNREJECT')"
          >
            <v-icon>mdi-refresh</v-icon>
          </v-btn>
        </div>
      </div>
    </div>

    <div>
      <v-data-table
        v-model="multipleId"
        :headers="tableHeaders"
        :items="dataCandidate"
        :page.sync="queryData.page"
        :items-per-page="queryData.limit"
        :loading="loading"
        hide-default-footer
        show-select
        disable-pagination
        class="elevation-0"
      >
        <template #item.status_updated_at="{ item }">
          {{ dateTimeFormat(item.status_updated_at) }}
        </template>
        <template #item.actions="{ item }">
          <div class="d-flex">
            <v-tooltip left>
              <template #activator="{ on }">
                <v-btn
                  color="pink"
                  class="mr-1"
                  icon
                  v-on="on"
                  @click="showConfirmEmail(item)"
                >
                  <v-icon>mdi-send</v-icon>
                </v-btn>
              </template>
              <span>{{ $t("master_data.table.send_email") }}</span>
            </v-tooltip>
            <v-tooltip left>
              <template #activator="{ on }">
                <v-btn
                  color="success"
                  class="mr-1"
                  icon
                  v-on="on"
                  @click="downloadPdf(item)"
                >
                  <v-icon>mdi-download</v-icon>
                </v-btn>
              </template>
              <span>
                {{
                  menuType == "teacher"
                    ? $t("master_data.download_teacher_applicant_detail")
                    : $t("master_data.download_staff_applicant_detail")
                }}
              </span>
            </v-tooltip>
            <v-tooltip left>
              <template v-slot:activator="{ on }">
                <v-btn
                  text
                  icon
                  color="#3e4295"
                  v-on="on"
                  @click="toDetail(item.id)"
                  :disabled="isDisabled"
                >
                  <v-icon>mdi-eye</v-icon>
                </v-btn>
              </template>
              <span>{{ $t("master_data.detail") }}</span>
            </v-tooltip>
            <v-tooltip v-if="queryData.status == 'UNPROCESSED'" left>
              <template #activator="{ on }">
                <v-btn
                  text
                  icon
                  color="error"
                  v-on="on"
                  @click="toggleModalConfirm(item.id, 'REJECT')"
                  :disabled="isDisabled"
                >
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </template>
              <span>{{ $t("master_data.student.decline") }}</span>
            </v-tooltip>
            <v-tooltip v-if="queryData.status == 'UNPROCESSED'" left>
              <template #activator="{ on }">
                <v-btn
                  text
                  icon
                  color="primary"
                  v-on="on"
                  @click="toggleModalConfirm(item.id, 'ACCEPT')"
                  :disabled="isDisabled"
                >
                  <v-icon>mdi-checkbox-marked-outline</v-icon>
                </v-btn>
              </template>
              <span>{{ $t("master_data.student.accept") }}</span>
            </v-tooltip>
            <v-tooltip v-if="queryData.status != 'UNPROCESSED'" left>
              <template #activator="{ on }">
                <v-btn
                  text
                  icon
                  color="purple"
                  v-on="on"
                  @click="toggleModalConfirm(item.id, 'UNREJECT')"
                  :disabled="isDisabled"
                >
                  <v-icon>mdi-refresh</v-icon>
                </v-btn>
              </template>
              <span>{{ $t("master_data.student.unprocessed") }}</span>
            </v-tooltip>
          </div>
        </template>
      </v-data-table>
      <Pagination
        v-if="dataCandidate.length > 0"
        :length="length"
        :total="total"
        :current-page="queryData.page"
        :limit="queryData.limit"
        :handler="paginateChange"
      />
    </div>
    <ModalConfirm
      :model="isModalConfirmEmail"
      :title="$t('master_data.table.send_email')"
      :close="
        () => {
          this.isModalConfirmEmail = false;
        }
      "
      :save="sendEmail"
      :loadingBtn="loadingBtnEmail"
      :content="$t('master_data.dialog_email')"
    />
    <ModalConfirm
      :model="isModalConfirm"
      :title="$t('master_data.student.dialog.title_1')"
      :close="toggleModalConfirm"
      :save="save"
      :loadingBtn="loadingBtn"
      :content="contentDialog"
    />
  </div>
</template>

<script>
import { getApplicant } from "@/api/admin/master/applicant";
import { downloadApplicantDetail } from "@/api/admin/master";

import {
  rejectEmployee,
  acceptEmployee,
  unRejectApplicant
} from "@/api/admin/master/applicant";
import { resendEmail } from "@/api/admin/admin";

import moment from "moment/moment";
moment.locale("id");

export default {
  props: {
    namaMenu: {
      type: String,
      required: true
    },
    menuType: String,
    dataContentDialog: {
      type: Array,
      required: true
    }
  },
  components: {
    Pagination: () => import("@/components/Pagination"),
    ModalConfirm: () => import("../components/ModalConfirm")
  },
  data() {
    return {
      dataCandidate: [],
      loading: false,
      isModalConfirmEmail: false,
      loadingBtnEmail: false,
      multipleId: [],
      singleId: [],
      length: 0,
      total: 0,
      loadingDownloadExcel: false,
      queryData: {
        page: 1,
        limit: 10,
        sort: ["name"],
        order: "DESC",
        search: "",
        type: this.menuType.toUpperCase(),
        status: "UNPROCESSED"
      },
      isModalConfirm: false,
      isDisabled: false,
      loadingBtn: false,
      contentDialog: "",
      status: {
        isAccepted: false,
        isRejected: false,
        isUnrejected: false
      },
      bodyEmail: {
        person: 0,
        email: "",
        type: ""
      }
    };
  },
  computed: {
    tableHeaders() {
      const rejectOn = {
        text: this.$i18n.t("master_data.table.reject_on"),
        value: "status_updated_at",
        sortable: false,
        width: 170
      };

      let headers = [
        {
          text: this.$i18n.t("master_data.table.name"),
          align: "left",
          value: "name"
        },
        { text: "Username", value: "username" },
        { text: this.$i18n.t("master_data.table.phone"), value: "phone" },
        { text: "Email", value: "email" },
        {
          text: this.$i18n.t("app.action"),
          align: "center",
          value: "actions",
          sortable: false,
          width: 120
        }
      ];
      if (this.queryData.status === "REJECTED") {
        headers.splice(2, 0, rejectOn);
      }
      return headers;
    },
    fields() {
      let result = {
        [this.$i18n.t("master_data.table.name")]: {
          field: "name",
          callback: value => this.preprocessLongNum(value)
        },
        Username: "username",
        [this.$i18n.t("master_data.table.reject_on")]: {
          field: "status_updated_at",
          callback: value => this.dateTimeFormat(value)
        },
        [this.$i18n.t("master_data.table.phone")]: {
          field: "phone",
          callback: value => this.preprocessLongNum(value)
        },
        Email: "email"
      };
      if (this.queryData.status === "UNPROCESSED") {
        delete result[this.$i18n.t("master_data.table.reject_on")];
      }
      return result;
    }
  },
  watch: {
    multipleId() {
      this.multipleId.length > 0
        ? (this.isDisabled = true)
        : (this.isDisabled = false);
    },
    "$route.query.tab": {
      immediate: true,
      handler() {
        if (this.$route.query.tab == 2) this.getCandidate();
      }
    },
    queryData: {
      immediate: true,
      handler() {
        this.getCandidate();
      }
    }
  },
  methods: {
    preprocessLongNum(value) {
      if (String(value).startsWith("0x")) {
        return value;
      }
      if (!isNaN(value) && value != "") {
        return `="${value}"`;
      }
      return value;
    },
    dateTimeFormat(value) {
      if (!value) return "";
      return moment(value).format("DD MMM YYYY, HH:mm");
    },
    showConfirmEmail(item) {
      let type;
      switch (this.queryData.status) {
        case "UNPROCESSED":
          type = "MAIL_PPDB_APPLIED_EMPLOYEE";
          break;
        case "REJECTED":
          type = "MAIL_PPDB_ACCEPT_EMPLOYEE";
          break;
      }
      const body = {
        person: item.person,
        email: item.email,
        type: type
      };
      this.bodyEmail = body;
      this.isModalConfirmEmail = true;
    },
    async sendEmail() {
      this.loadingBtnEmail = true;
      const res = await resendEmail(this.bodyEmail);
      if (res.data.code) {
        this.snackBar(true, res.data.message);
      } else {
        this.snackBar(false, res.data.message);
      }
      this.loadingBtnEmail = false;
      this.isModalConfirmEmail = false;
    },
    async downloadPdf(item) {
      this.$store.commit("TOGGLE_FULL_LOADING");
      downloadApplicantDetail({ person: item.person })
        .then(res => {
          if (res.data.code) {
            window.open(res.data.data.path);
          } else {
            this.$store.commit("CALL_SNACKBAR", {
              msg: res.data.message,
              color: "error"
            });
          }
          this.$store.commit("TOGGLE_FULL_LOADING");
        })
        .catch(() => this.$store.commit("TOGGLE_FULL_LOADING"));
    },
    async downloadExcel() {
      this.loadingDownloadExcel = true;
      const query = JSON.parse(JSON.stringify(this.queryData));
      query.limit = this.total;
      query.page = 1;

      const res = await getApplicant(query);
      this.loadingDownloadExcel = false;
      if (res.data.code) {
        if (res.data.data.data.length == 0) {
          this.snackBar(false, this.$i18n.t("app.data_not_found"));
        } else return res.data.data.data;
      }
    },
    paginateChange(page, limit) {
      this.queryData.page = page;
      this.queryData.limit = limit;
      this.getCandidate();
    },
    unRejected() {
      this.loadingBtn = true;
      let msgSuccess = "";
      if (this.queryData.type === "TEACHER") {
        msgSuccess = this.$i18n.t(
          "master_data.teacher.response.success_unprocessed"
        );
      } else {
        msgSuccess = this.$i18n.t(
          "master_data.teacher.response.success_unprocessed"
        );
      }
      if (this.multipleId.length > 0 || this.singleId.length > 0) {
        unRejectApplicant({
          id:
            this.multipleId.length > 0
              ? this.multipleId.map(d => d.id)
              : this.singleId
        })
          .then(res => {
            if (res.data.code) {
              this.multipleId = [];
              this.singleId = [];
              this.snackBar(true, msgSuccess);
              this.toggleModalConfirm();
              this.getCandidate();
            } else {
              this.snackBar(false, res.data.message);
            }
            this.loadingBtn = false;
          })
          .catch(() => {
            this.loadingBtn = false;
          });
      }
    },
    toggleModalConfirm(id, action) {
      if (id) {
        this.singleId.push(id);
      }
      if (action == "ACCEPT") {
        this.status.isAccepted = true;
        this.contentDialog = this.dataContentDialog[0];
      } else if (action == "REJECT") {
        this.status.isRejected = true;
        this.contentDialog = this.dataContentDialog[1];
      } else {
        this.status.isUnrejected = true;
        this.contentDialog = this.dataContentDialog[2];
      }
      this.isModalConfirm = !this.isModalConfirm;
    },
    toDetail(id) {
      this.$router.push({
        path: `${this.$route.name.toLowerCase()}-manual`,
        query: { id: id, applicant: true }
      });
    },
    save() {
      if (this.status.isAccepted) {
        this.accepted();
      } else if (this.status.isRejected) {
        this.rejected();
      } else {
        this.unRejected();
      }
    },
    snackBar(isSuccess, message) {
      this.$store.commit("CALL_SNACKBAR", {
        msg: message,
        color: isSuccess ? "success" : "error"
      });
    },
    accepted() {
      this.loadingBtn = true;
      let msgSuccess = "";
      if (this.queryData.type === "TEACHER") {
        msgSuccess = this.$i18n.t(
          "master_data.teacher.response.success_accept_teacher"
        );
      } else {
        msgSuccess = this.$i18n.t(
          "master_data.teacher.response.success_accept_staff"
        );
      }
      if (this.multipleId.length > 0 || this.singleId.length > 0) {
        acceptEmployee({
          id:
            this.multipleId.length > 0
              ? this.multipleId.map(d => d.id)
              : this.singleId
        })
          .then(res => {
            if (res.data.code) {
              this.multipleId = [];
              this.singleId = [];
              this.snackBar(true, msgSuccess);
              this.toggleModalConfirm();
              this.getCandidate();
            } else {
              this.snackBar(false, res.data.message);
            }
            this.loadingBtn = false;
          })
          .catch(() => {
            this.loadingBtn = false;
          });
      }
    },
    rejected() {
      this.loadingBtn = true;
      let msgSuccess = "";
      if (this.queryData.type === "TEACHER") {
        msgSuccess = this.$i18n.t(
          "master_data.teacher.response.success_reject_teacher"
        );
      } else {
        msgSuccess = this.$i18n.t(
          "master_data.staff.response.success_reject_staff"
        );
      }
      if (this.multipleId.length > 0 || this.singleId.length > 0) {
        rejectEmployee({
          id:
            this.multipleId.length > 0
              ? this.multipleId.map(d => d.id)
              : this.singleId
        })
          .then(res => {
            if (res.data.code) {
              this.multipleId = [];
              this.singleId = [];
              this.snackBar(true, msgSuccess);
              this.toggleModalConfirm();
              this.getCandidate();
            } else {
              this.snackBar(false, res.data.message);
            }
            this.loadingBtn = false;
          })
          .catch(() => {
            this.loadingBtn = false;
          });
      }
    },
    getCandidate() {
      this.reset();
      this.loading = true;
      getApplicant(this.queryData).then(res => {
        this.loading = false;
        const r = res.data.data;
        this.dataCandidate = r.data;
        this.length = r.last_page;
        this.total = r.total;
      });
    },
    reset() {
      this.total = 0;
      this.multipleId = [];
      this.dataCandidate = [];
      this.status.isAccepted = false;
      this.status.isRejected = false;
      this.status.isUnrejected = false;
    }
  }
};
</script>
