import request from "@/utils/request";

export function get_mutation_list(data) {
  return request({
    method: "post",
    url: "mutation/list",
    data: data
  });
}

export function add_mutation(data) {
  return request({
    method: "post",
    url: "mutation/create",
    data: data
  });
}

export function update_mutation(data) {
  return request({
    method: "post",
    url: "mutation/cancel",
    data: data
  });
}

export function downloadApplicantDetail(data) {
  return request({
    method: "post",
    url: "applicant/pdf_by_person",
    data: data
  });
}

export function downloadPersonDetail(data) {
  return request({
    method: "post",
    url: "student/pdf_by_person",
    data: data
  });
}

export function majorList() {
  return request({
    method: "post",
    url: "/major/dropdown"
  });
}
